<template>
  <div>
    <!-- 切换分类 -->
    <div class="category-bar flex-row-between-center">
      <div :class="currentCategoryIndex === index ? 'category-active category-btn' : 'category-btn'" v-for="(item, index) in categories" :key="index" @click="handleChangeStatus(index)">{{ item }}</div>
    </div>

    <!-- 列表 -->
    <div class="list">
      <!-- 列表项目 -->
      <div class="list-item" v-for="(item, index) in list" :key="index" @click="handleSwitchPage(item.memberInfoExchangeID)">
        <!-- 内容块 -->
        <div class="body">
          <!-- 标题 -->
          <div class="title-line flex-row-between-center">
            <span class="title one-line">{{currentCategoryIndex === 0 ? item.title : item.memberInfoExchange.title}}</span>
            <span v-if="currentCategoryIndex === 0" :class="item.status + ' status'">{{item.status ? '已发布' : '待审核'}}</span>
          </div>
          <!-- 内容 -->
          <div v-if="currentCategoryIndex === 0" class="content">
            <div>所属分类：{{item.categoryLabel}}</div>
            <div>发布时间：{{item.createdAt}}</div>
            <div>联系人：{{item.username}}</div>
            <div>联系方式：{{item.phoneNumber}}</div>
          </div>
          <!-- 评价内容 -->
          <template v-else>
            <div class="content">
              <div>留言人：{{item.user.name}}</div>
              <div v-if="item.member.companyName !== ''">所属公司：{{item.member.companyName}}</div>
            </div>
            <div class="content">留言内容：{{item.content}}</div>
          </template>
          <!-- 按钮行 -->
          <div class="btn-line flex-row-end-center">
            <div class="delete-btn" @click.stop="handleDelete(item.currentCategoryIndex === 0 ? item.memberInfoExchangeID : item.commentID)">删除</div>
          </div>
        </div>
      </div>
    </div>

    <!-- 底标 -->
    <div class="no-more" v-if="isEnd">没有更多内容了</div>
  </div>
</template>

<script>
import page from '@/mixins/page'
import { MessageBox, Toast } from 'mint-ui'

export default {
  name: 'UserExchangeInfoList',

  data () {
    return {
      currentCategoryIndex: 0,
      categories: ['交流信息', '相关留言'],
      list: [],
      keyword: '',
      currentPage: 1,
      pageSize: 8,
      isEnd: false
    }
  },

  mixins: [page],

  created () {
    this.pageSize = Math.ceil((this.getWindowHeight() - 30 - 32) / 103)
    this.getAllMemberExchangeInfo()
  },

  beforeRouteLeave (to, from, next) {
    from.meta.keepAlive = false
    next()
  },

  methods: {
    // 获取自己发布的交流信息
    getAllMemberExchangeInfo () {
      this.api.getAllMemberExchangeInfoForUser({ currentPage: this.currentPage, pageSize: this.pageSize }).then(res => {
        if (res.data.length === 0 || res.data.length < this.pageSize) this.isEnd = true
        const mapping = {
          Recruitment: '招聘',
          ProjectCooperation: '项目合作',
          ActivityInfo: '活动信息',
          Other: '其它'
        }
        for (const item of res.data) {
          item.categoryLabel = mapping[item.category]
        }
        this.list = [...this.list, ...res.data]
      }).catch(err => {
        if (err.status === 401) this.$store.commit('addLoginCallBack', () => { this.getAllMemberExchangeInfo() })
      })
    },
    // 获取所有给自己的留言
    getAllComment () {
      this.api.getAllCommentForUser({ currentPage: this.currentPage, pageSize: this.pageSize }).then(res => {
        if (res.data.length === 0 || res.data.length < this.pageSize) this.isEnd = true
        this.list = [...this.list, ...res.data]
      }).catch(err => {
        if (err.status === 401) this.$store.commit('addLoginCallBack', () => { this.getAllComment() })
      })
    },
    // 切换分类
    handleChangeStatus (index) {
      if (this.currentCategoryIndex === index) return
      this.currentCategoryIndex = index
      if (index === 0) {
        this.initPage()
        this.getAllMemberExchangeInfo()
      } else {
        this.initPage()
        this.getAllComment()
      }
    },
    // 切换页面
    handleSwitchPage (id) {
      this.$router.push({ path: '/member-exchange-info-detail', query: { id } })
    },
    // 触底函数
    hitBottom () {
      if (this.isEnd) return
      this.currentPage++
      this.getAllMemberExchangeInfo()
    },
    // 删除
    handleDelete (id) {
      MessageBox.confirm(this.currentCategoryIndex === 0 ? '是否确认删除交流信息？' : '是否确认删除该条留言？').then(() => {
        this.handleConfirmDelete(id)
      }).catch(() => {})
    },
    // 确认删除
    handleConfirmDelete (id) {
      if (this.currentCategoryIndex === 0) {
        this.api.delMemberExchangeInfo({ id }).then(res => {
          Toast(res.msg || '删除成功')
          this.initPage()
          this.getAllMemberExchangeInfo()
        }).catch(err => {
          if (err.status === 401) this.$store.commit('addLoginCallBack', () => { this.handleConfirmDelete() })
        })
      } else {
        this.api.delComment({ id }).then(res => {
          Toast(res.msg || '删除成功')
          this.initPage()
          this.getAllComment()
        }).catch(err => {
          if (err.status === 401) this.$store.commit('addLoginCallBack', () => { this.handleConfirmDelete() })
        })
      }
    }
  }
}
</script>

<style scoped>
.category-bar {
  padding: 0 15px;
  font-size: 14px;
}
.category-btn {
  width: 45vw;
  height: 40px;
}
.list {
  margin: 15px;
}
.list-item {
  border: 1px solid #dddddd;
  background: #fff;
  border-radius: 8px;
  box-shadow: 2px 2px 2px #eeeeee;
}
.list-item + .list-item {
  margin-top: 10px;
}

.list-item > img {
  object-fit: cover;
  width: 100%;
  max-height: 300px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.body {
  padding: 5px 15px;
  font-size: 15px;
  color: var(--normal-font-color);
}

.title-line {
  font-weight: bold;
  padding: 10px;
  border-bottom: 1px solid #f5f5f5;
}
.title {
  width: 80%;
}
.status {
  font-size: 14px;
}
.true {
  color: var(--main-color);
}
.false {
  color: #aaa;
}

.content {
  padding: 10px;
  font-size: 13px;
  color: #999;
}

.btn-line {
  padding: 10px 0;
  border-top: 1px solid #f5f5f5;
  font-size: 14px;
}
.btn-line > div {
  margin-left: 10px;
  padding: 5px 20px;
  border-radius: 5px;
}
.delete-btn {
  background: #dd6975;
  color: #fff;
}
</style>
